// @NOTE: This is the the date when it was most recently updated, if you make any legal changes to the content be sure to update this date
export const visitConsentDate = '2024-08-15';

export const visitConsentContent = `
* I understand that capitalized terms used in this Informed Consent to Telehealth and Virtual Care that are not defined herein will have the meaning ascribed to them in the Pocket RN [Terms of Service](https://www.pocketrn.com/policies/terms-of-service).
* I am either: (i) the patient seeking the Virtual Care and/or the Services; (ii) the legal guardian or personal representative of such patient; (iii) the general, durable or healthcare power of attorney of such patient or (iv) a connected caregiver (collectively, “**I**,” “**me**,” or “**my**”).
* I am over the age of 18.
* I understand there are risks involved in any medical procedure or treatment. By acknowledging “I AGREE,” I am consenting to, requesting and authorizing PocketRN and its Professionals to perform Virtual Care as may be necessary in accordance with the judgment of the attending medical practitioner(s) and/or in their professional judgment, may be advisable to my well-being. I acknowledge I am responsible for providing accurate information about my health condition(s) and any symptoms I am experiencing as well as any healthcare providers who have treated such conditions and symptoms. I acknowledge no guarantee can be made or assurance of a success be provided by anyone concerning the results of any Virtual Care.
* I am not under the influence of any medications or other substances that could impair my understanding of the information in these Terms. I have had sufficient time to read and understand the information provided above regarding the Services, including the Virtual Care. I have had the opportunity to discuss this consent with my treating Professional providing Virtual Care through Pocket RN. I have been given all of the opportunities I require to ask any and all of my questions, and such questions have been answered to my satisfaction in words I understand.
* I grant my permission for Virtual Care and/or other Services to be performed by my Professional using remote electronic communications, such as audio and/or video communications, specifically through use of the Site, where such medical care may include diagnosis, consultation, treatment, and transfer of electronic medical records and exchange of medical data. I authorize Pocket RN and the Professional to release the information gained from the Services to my primary care physician(s), health care provider, and insurance company (to assist with claim reimbursement if relevant). I understand and agree that the information in this file will be kept for a period that is required by applicable federal and state law. Additionally, I understand that other medical personnel and non-medical technical personnel may join the Virtual Care virtually to aid in the delivery of medical care to me by a Professional.
* I understand and agree that, as part of receiving the Virtual Care, I may not be able to select a specific Professional. Further, I understand and agree that, due to emergencies, scheduling, and other circumstances, Pocket RN cannot warrant or otherwise guarantee, and does not warrant or otherwise guarantee, that I will have access to the Professionals, including any specific Professional.
* I acknowledge and accept that if my Virtual Care includes a physical examination portion, it will be delivered wholly virtually through the Site in reliance upon video, images, telephone consultations, medical records and/or otherwise, which may be recorded. I accept this, with full knowledge, of all potential benefits and consequences from virtual care and deem this method of physical examination appropriate and complete, but I may not, because as with all medical or health care services provided, no results or outcomes can be guaranteed. In fact, as with all medical or health care services provided, I may be subject to virtual care that may cause some harm, including potentially serious harm.
* I understand that a variety of alternative methods of medical care may be available to me, and that I may choose one or more of these at any time. The Professional has explained these alternatives to my satisfaction.
* I acknowledge that, in the exercise of their clinical judgment, a Professional may determine: (1) that the nature of my problem is such that it is not professionally appropriate to assist me with that problem through virtual care; or (2) that it may not be lawful for the Professional to diagnose or treat me virtually; or (3) both. Should the Professional make any such determination that they will be unable to assist me virtually, he/she will confer with me about other possible approaches to handling my medical problems, such as referring me to my primary care physician.
* I acknowledge virtual care may not be appropriate or a substitute for emergency medical care. In the event of an urgent health issue or concern, I acknowledge it is my responsibility to seek care at a facility delivering urgent or emergent care services.
* I understand that it is my duty to inform my primary care provider of any electronic interactions regarding my health care that I may have with other health care providers to ensure my provider has my full clinical background when making treatment decisions.
* I will provide my Professional and Pocket RN with the names and contact information for other relevant healthcare providers for me, and my Professional may communicate with them. It is my responsibility to provide accurate information and to keep it updated.
* I understand that it is my choice to have someone else present during my Virtual Care session, and that anyone who sits in on the virtual session will have access to my healthcare information and my confidentiality may not be guaranteed. I understand that if I include any third party on an electronic exchange with Pocket RN, I am granting permission for Pocket RN to communicate my health information with that third party. Pocket RN and/or the Professional will not initiate inclusion of any third party on an electronic exchange. I acknowledge that Pocket RN and/or the Professional is not responsible for any breach of confidentiality made by any person present I invite to be present during a visit, or added by me as a third party.
* I give permission to Pocket RN and/or a Professional to take a photograph of me or record my Virtual Care session in furtherance of my care. These photos or video recordings will not be published without my express consent, but they may be shared with my primary care physician or other healthcare provider that I listed and provided to Pocket RN.
* I understand that the Professionals I engage with through the Site will not prescribe medications to treat my problem and notwithstanding other licenses or qualifications are only engaging with me as a registered nurse.
* I understand that virtual care may involve electronic communication of my personal medical information to other medical professionals who may be located in other areas, including out of state. I understand that the laws that protect privacy and the confidentiality of medical information also apply to virtual care, and that no information obtained in receiving virtual care, which identifies me, will be disclosed to researchers or other entities without my consent. I also acknowledge, however, that the security and privacy of electronic communications cannot be guaranteed.
* I have read and understand the Pocket RN Terms of Service, [Privacy Policy](https://www.pocketrn.com/policies/privacy) and the [Notice of Privacy Practices](https://www.pocketrn.com/policies/notice-of-privacy-practices).
* I understand that I have the right to inspect all information obtained and recorded in the course of a virtual care interaction, and may receive copies of this information for a reasonable fee.
* I understand that I have the right to withhold or withdraw my consent to receive Virtual Care at any time, without affecting my right to future care or treatment.
* Neither Pocket RN nor any third party is liable for any professional or other advice I obtain from a Professional via the Virtual Care session, or for any information obtained through the Service. I acknowledge my reliance on any Professional, or information provided during the Virtual Care session, is solely at my own risk and I assume full responsibility for all risk associated therewith. I hereby certify that I am physically located in the state I have entered as my current location for the Virtual Care. I acknowledge that my ability to access and use the Site, including receiving the Virtual Care, is conditioned upon the truthfulness of this certification, and that the Professionals I access through the Site and Virtual Care sessions are relying upon this certification in order to interact with me. In the event that my certification is inaccurate, I agree to indemnify Pocket RN and the Professionals I interact with from any resulting damages, costs, or claims.
* By acknowledging “ACCEPT TERMS” or “I AGREE,” I hereby authorize Pocket RN and any Professional performing Services through the Site to provide care to me virtually in the course of my engagement and treatment, as applicable.
* I agree that my electronic agreement to these Terms is equivalent to the signature of a patient to this Informed Consent to Telehealth and Virtual Care. I understand a copy of this informed consent form is available by printing this document or by request.
`;

import {
  AccountType,
  OptOutPhone,
  OptOutPhoneFactory,
  User,
  AccountStatus,
  ProfileSettings,
  Permissions,
  StripePrice,
  StripeSubscription,
  StripeInvoice,
  UserFactory,
  StripePaymentMethod,
  StripeCustomerBillingAddress,
  Provider,
  ProviderFactory,
  ProviderJson,
  UserPhoneJson,
  OptOutPhoneJson,
  UserDiscount,
  SubscriptionDiscount,
  SubscriptionDiscountFactory,
  SubscriptionDiscountJson,
  UserJson,
  AccountJson,
  Account,
  LinkedUser,
  AccountFactory,
  LinkedUserFactory,
  ClientActionLink,
  LinkedUserJson,
  ActionLinkFactory,
  ClientActionLinkJson,
} from '@pocketrn/entities/dist/core';
import { FirebaseSDK, FirebaseFunctionInterface, InvalidArgument, OnCallFunction, ManagedProperty } from '@pocketrn/client/dist/entity-sdk';
import { InvalidPhoneCode } from './error';
import { Auth } from 'firebase/auth';
import { Person, PersonFactory, PersonJson } from '@pocketrn/entities/dist/community';
import { StateAbbreviation } from '@pocketrn/localizer';
import { SessionUserSDK } from '../../apps/user-state';
import { AccountOwner } from '../../components/pages/invite/steps/userDraft';
import { CallTypeFactory, ClientCustomCallType, ClientCustomCallTypeJson } from '@pocketrn/entities/dist/meeting';

export interface LinkedUsersMaps {
  linkedUsers: LinkedUser[];
  usersMap: Record<string, User>;
  personsMap: Record<string, Person>;
  accounts: Account[];
  providersMap: Record<string, Provider>;
  customCallTypesMap: Record<string, ClientCustomCallType>;
};

export class CoreSDK extends FirebaseSDK {
  constructor(functions: FirebaseFunctionInterface, firebaseAuth: Auth) {
    super(functions, firebaseAuth);
  }

  public async getLinkedUsers(options?: {
    filterTo?: {
      status: AccountStatus;
      operator: '==' | '!=';
    }
    managed?: ManagedProperty;
  }): Promise<LinkedUsersMaps> {
    try {
      const data: any = {};
      if (options?.filterTo) {
        data.filterTo = {
          ...options.filterTo,
        };
      }
      const resp = await this.functions.httpsCallable(
        OnCallFunction.Core,
        'getLinkedUsers',
        options?.managed,
      )(data);
      return this.buildLinkedUsersListAndMaps(resp.data);
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  private buildLinkedUsersListAndMaps(data: {
    linkedUsers: LinkedUserJson[];
    usersMap: Record<string, UserJson>;
    personsMap: Record<string, PersonJson>;
    accounts: AccountJson[];
    providersMap: Record<string, ProviderJson>;
    customCallTypesMap: Record<string, ClientCustomCallTypeJson>;
  }): LinkedUsersMaps {
    return {
      linkedUsers: data.linkedUsers.map(
        (linkedUser: LinkedUserJson) => LinkedUserFactory.build(linkedUser),
      ),
      usersMap: Object.keys(data.usersMap).reduce((acc, uid) => {
        const userData = data.usersMap[uid];
        acc[uid] = UserFactory.build(userData);
        return acc;
      }, {} as Record<string, User>),
      personsMap: Object.keys(data.personsMap).reduce((acc, uid) => {
        const personData = data.personsMap[uid];
        acc[uid] = PersonFactory.build(personData);
        return acc;
      }, {} as Record<string, Person>),
      accounts: data.accounts.map(
        (account: AccountJson) => AccountFactory.build(account),
      ),
      providersMap: Object.keys(data.providersMap).reduce((acc, id) => {
        const providerData = data.providersMap[id];
        acc[id] = ProviderFactory.build(providerData);
        return acc;
      }, {} as Record<string, Provider>),
      customCallTypesMap: Object.keys(data.customCallTypesMap).reduce((acc, id) => {
        const customCallTypeData = data.customCallTypesMap[id];
        acc[id] = CallTypeFactory.buildClientCustom(customCallTypeData);
        return acc;
      }, {} as Record<string, ClientCustomCallType>),
    };
  }

  public async getPrices(productKeys: string[]): Promise<StripePrice[]> {
    try {
      const resp = await this.functions.httpsCallable(
        OnCallFunction.Core,
        'getPrices',
      )({ productKeys });
      if (!resp.data.prices) {
        return [];
      }
      return resp.data.prices;
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async getPaymentMethods(): Promise<StripePaymentMethod[]> {
    try {
      const resp = await this.functions.httpsCallable(
        OnCallFunction.Core,
        'getPaymentMethods',
      )({});
      if (!resp.data.paymentMethods) {
        return [];
      }
      return resp.data.paymentMethods;
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async createSubscription(priceId: string): Promise<{subscriptionId: string}> {
    try {
      const resp = await this.functions.httpsCallable(
        OnCallFunction.Core,
        'createSubscription',
      )({ priceId });
      return resp.data;
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async resumeSubscription(subscriptionId: string): Promise<void> {
    try {
      await this.functions.httpsCallable(
        OnCallFunction.Core,
        'resumeSubscription',
      )({ subscriptionId });
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async cancelSubscription(subscriptionId: string): Promise<void> {
    try {
      await this.functions.httpsCallable(
        OnCallFunction.Core,
        'cancelSubscription',
      )({ subscriptionId });
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async updateSubscription(subscriptionId: string, newProductKey: string): Promise<void> {
    try {
      await this.functions.httpsCallable(
        OnCallFunction.Core,
        'updateSubscription',
      )({ subscriptionId, newProductKey });
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async getSubscriptions(): Promise<StripeSubscription[]> {
    try {
      const resp = await this.functions.httpsCallable(
        OnCallFunction.Core,
        'getSubscriptions',
      )({});
      if (!resp.data.subscriptions) {
        return [];
      }
      return resp.data.subscriptions;
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async getUpcomingInvoice(subscriptionId: string): Promise<StripeInvoice> {
    try {
      const resp = await this.functions.httpsCallable(
        OnCallFunction.Core,
        'getSubscriptionUpcomingInvoice',
      )({ subscriptionId });
      return resp.data.invoice;
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async createNewPaymentMethod(): Promise<{
    clientSecret: string, setupIntentId?: string}> {
    try {
      const resp = await this.functions.httpsCallable(
        OnCallFunction.Core,
        'createNewPaymentMethod',
      )({});
      return resp.data;
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async updateCustomerAddress(address: StripeCustomerBillingAddress): Promise<void> {
    try {
      const data = { address };
      await this.functions.httpsCallable(
        OnCallFunction.Core,
        'updateCustomerAddress',
      )(data);
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async removePaymentMethods(): Promise<void> {
    try {
      await this.functions.httpsCallable(
        OnCallFunction.Core,
        'removePaymentMethods',
      )({});
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async getSubscriptionDiscounts(): Promise<UserDiscount[]> {
    try {
      const resp = await this.functions.httpsCallable(
        OnCallFunction.Core,
        'getSubscriptionDiscounts',
      )({});
      return resp.data.discounts ?? [];
    } catch (err) {
      throw await this.handleErr(err);
    }
  };

  public async getUserDiscountsWithProviders(): Promise<SubscriptionDiscount[]> {
    try {
      const resp = await this.functions.httpsCallable(
        OnCallFunction.Core,
        'getUserDiscountsWithProviders',
      )({});
      const discounts: SubscriptionDiscount[] = resp.data.discounts.map(
        (discount: SubscriptionDiscountJson) => {
          return SubscriptionDiscountFactory.build(discount);
        },
      );
      return discounts;
    } catch (err) {
      throw await this.handleErr(err);
    }
  };

  public async ackAccountInvitation(
    status: AccountStatus,
    type: AccountType,
    providerId: string | undefined,
    managed?: ManagedProperty,
  ): Promise<void> {
    try {
      const data: any = {
        status,
        type,
        providerId: providerId ?? null,
      };
      await this.functions.httpsCallable(
        OnCallFunction.Core,
        'ackAccountInvitation',
        managed,
      )(data);
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async getOptOutPhones(managed?: ManagedProperty): Promise<OptOutPhone[]> {
    try {
      const resp = await this.functions.httpsCallable(
        OnCallFunction.Core,
        'getOptOutPhones',
        managed,
      )({});
      if (!resp.data.optOutPhones) {
        return [];
      }
      return resp.data.optOutPhones.map((p: OptOutPhoneJson) => OptOutPhoneFactory.build(p));
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async sendVerificationPhone(phone: string): Promise<void> {
    try {
      const data = { phone };
      await this.functions.httpsCallable(
        OnCallFunction.Core,
        'sendVerificationPhone',
      )(data);
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async verifyPhoneCode(phone: UserPhoneJson, code: string): Promise<void> {
    try {
      const data = { phone, code };
      await this.functions.httpsCallable(
        OnCallFunction.Core,
        'verifyPhoneCode',
      )(data);
    } catch (err) {
      try {
        throw await this.handleErr(err);
      } catch (err) {
        if (err instanceof InvalidArgument) {
          throw new InvalidPhoneCode();
        }
        throw err;
      }
    }
  }

  public async setPhones(
    phones: UserPhoneJson[],
    options?: { impersonatedUid?: string, managed?: ManagedProperty},
  ): Promise<void> {
    try {
      const data = {
        phones,
        _impersonatedUid: options?.impersonatedUid,
      };
      await this.functions.httpsCallable(
        OnCallFunction.Core,
        'setPhones',
        options?.managed,
      )(data);
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async waitlistRegistration(email: string): Promise<void> {
    try {
      const message = 'Waitlist Registration Request Submitted';
      const data = { message, email };
      await this.functions.httpsCallable(
        OnCallFunction.Help,
        'contactUs',
      )(data);
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async setProfileSettings(profileSettings: ProfileSettings): Promise<void> {
    try {
      const data = { profileSettings: {
        enableSmsNotifications: profileSettings.enableSmsNotifications,
        notifyWhileUsingApp: profileSettings.notifyWhileUsingApp,
        enableEmailNotifications: profileSettings.enableEmailNotifications,
      } };
      await this.functions.httpsCallable(
        OnCallFunction.Core,
        'setProfileSettings',
      )(data);
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async setPerson(person: Person): Promise<void> {
    try {
      const personJson = person.json();
      const data: any = {
        person: {
          firstName: personJson.firstName,
          lastName: personJson.lastName,
          preferredName: personJson.preferredName,
          genderIdentity: personJson.genderIdentity,
          dateOfBirth: personJson.dateOfBirth,
        },
      };
      await this.functions.httpsCallable(
        OnCallFunction.Community,
        'updatePerson',
      )(data);
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async setActiveRegion(region: StateAbbreviation): Promise<void> {
    try {
      const data = { region };
      await this.functions.httpsCallable(
        OnCallFunction.Core,
        'setActiveRegion',
      )(data);
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async searchUser(email: string): Promise<User> {
    try {
      const data: any = { email };
      const res = await this.functions.httpsCallable(
        OnCallFunction.Core,
        'searchUser',
      )(data);
      return UserFactory.build(res.data.user);
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async createActionLink(
    actionLink: ClientActionLink,
    managed?: ManagedProperty,
  ): Promise<void> {
    try {
      const data = {
        type: actionLink.root.type,
        expiresAt: actionLink.root.expiresAt.toISOString(),
        password: actionLink.password,
        notify: {
          email: actionLink.notifyEmail,
          sms: actionLink.notifySms,
          uids: actionLink.notifyUids,
        },
        metadata: actionLink.metadata,
      };
      await this.functions.httpsCallable(
        OnCallFunction.Core,
        'createActionLink',
        managed,
      )(data);
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async getActionLinks(
    managed?: ManagedProperty,
  ): Promise<ClientActionLink[]> {
    try {
      const res = await this.functions.httpsCallable(
        OnCallFunction.Core,
        'getActionLinks',
        managed,
      )({});
      return res.data.actionLinks.map((al: ClientActionLinkJson) => {
        return ActionLinkFactory.buildClient(al);
      });
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async cancelActionLink(
    notify?: {
      uids: string[];
      email?: string;
      sms?: string;
    },
    actionLinkId?: string,
    cancelationReason?: string,
    managed?: ManagedProperty,
  ): Promise<void> {
    try {
      const data = { notify, cancelationReason, actionLinkId };
      await this.functions.httpsCallable(
        OnCallFunction.Core,
        'cancelActionLink',
        managed,
      )(data);
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async completeActionLink(
    managed?: ManagedProperty,
  ): Promise<void> {
    try {
      await this.functions.httpsCallable(
        OnCallFunction.Core,
        'completeActionLink',
        managed,
      )({});
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async setActionLinkMetadata(
    metadata: Record<string, any>,
  ): Promise<void> {
    try {
      const data = { metadata };
      await this.functions.httpsCallable(
        OnCallFunction.Core,
        'setActionLinkMetadata',
      )(data);
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async switchToCaregiver(
    managed?: ManagedProperty,
  ): Promise<{
    actionLink: ClientActionLink;
  }> {
    try {
      const res = await this.functions.httpsCallable(
        OnCallFunction.Core,
        'switchActionLinkToCaregiver',
        managed,
      )({});
      return {
        actionLink: ActionLinkFactory.buildClient(res.data.actionLink),
      };
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async inviteUserToLink(
    linkToUid: string,
    inviteType: AccountType.Caregiver | AccountType.Patient,
    providerId: string,
    managed?: ManagedProperty,
  ): Promise<string> {
    try {
      const data: any = { linkToUid, accountType: inviteType, providerId };
      const res = await this.functions.httpsCallable(
        OnCallFunction.Core,
        'inviteUserToLink',
        managed,
      )(data);
      return res.data.linkedUser.id;
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async addCaregiver(
    user: UserJson,
    person: PersonJson,
    account: AccountJson,
    managed?: ManagedProperty,
  ): Promise<{
    caregiver: {
      user: User;
      person: Person;
      account: Account;
      linkedUser: LinkedUser;
    };
  }> {
    try {
      const data = {
        user: {
          email: user.email,
          phones: user.phones.map((p: UserPhoneJson) => {
            return {
              number: p.number,
              type: p.type,
              notificationPreference: p.notificationPreference,
            };
          }),
        },
        person: {
          firstName: person.firstName,
          lastName: person.lastName,
          preferredName: person.preferredName,
        },
        account: {
          providerId: account.providerId,
        },
      };
      const res = await this.functions.httpsCallable(
        OnCallFunction.Core,
        'addCaregiver',
        managed,
      )(data);
      return {
        caregiver: {
          user: UserFactory.build(res.data.caregiver.user),
          person: PersonFactory.build(res.data.caregiver.person),
          account: AccountFactory.build(res.data.caregiver.account),
          linkedUser: LinkedUserFactory.build(res.data.caregiver.linkedUser),
        },
      };
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async addPatient(
    user: UserJson,
    person: PersonJson,
    account: AccountJson,
    ownerType: AccountOwner,
    owner?: {
      user: UserJson;
      person?: PersonJson;
    },
  ): Promise<{
    patient: {
      user: User;
      person: Person;
      account: Account;
      linkedUser: LinkedUser;
    };
    caregiver?: {
      user: User;
      linkedUser: LinkedUser;
    };
  }> {
    try {
      const data = {
        person: {
          firstName: person.firstName,
          lastName: person.lastName,
          preferredName: person.preferredName,
          dateOfBirth: person.dateOfBirth,
          genderIdentity: person.genderIdentity,
        },
        user: {
          activeRegion: user.activeRegion,
          phones: user.phones.length ? user.phones.map((p: UserPhoneJson) => {
            return {
              number: p.number,
              type: p.type,
              notificationPreference: p.notificationPreference,
            };
          }) : undefined,
        },
        account: {
          providerId: account.providerId,
        },
        ownerType,
        owner: owner ? {
          user: {
            email: owner.user.email,
            phones: owner.user.phones.map((p: UserPhoneJson) => {
              return {
                number: p.number,
                type: p.type,
                notificationPreference: p.notificationPreference,
              };
            }),
            uid: owner.user.uid || undefined,
          },
          person: owner.person?.firstName ? {
            firstName: owner.person.firstName,
            lastName: owner.person.lastName,
            preferredName: owner.person.preferredName,
          } : undefined,
        } : undefined,
      };
      const res = await this.functions.httpsCallable(
        OnCallFunction.Core,
        'addPatient',
      )(data);
      return {
        patient: {
          user: UserFactory.build(res.data.patient.user),
          person: PersonFactory.build(res.data.patient.person),
          account: AccountFactory.build(res.data.patient.account),
          linkedUser: LinkedUserFactory.build(res.data.patient.linkedUser),
        },
        caregiver: res.data.caregiver ? {
          user: UserFactory.build(res.data.caregiver.user),
          linkedUser: LinkedUserFactory.build(res.data.caregiver.linkedUser),
        } : undefined,
      };
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async updateLinkedUser(
    id: string,
    status: AccountStatus,
    managed?: ManagedProperty,
  ): Promise<void> {
    try {
      const data: any = { id, status };
      await this.functions.httpsCallable(
        OnCallFunction.Core,
        'updateLinkedUser',
        managed,
      )(data);
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async updatePermissions(
    id: string,
    permissions: Permissions,
    managed?: ManagedProperty,
  ): Promise<void> {
    try {
      const data: any = { id, permissions };
      await this.functions.httpsCallable(
        OnCallFunction.Core,
        'updatePermissions',
        managed,
      )(data);
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async joinStateWaitlist(
    name: string,
    email: string,
    message: string,
    state: string,
  ): Promise<void> {
    try {
      const data = { name, email, message, state };
      await this.functions.httpsCallable(
        OnCallFunction.Help,
        'contactUs',
      )(data);
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async acceptUserAcknowledgment(
    id: string,
    acceptedAt: Date,
    acceptedBy: string,
    coAcceptedBy?: string,
    managed?: ManagedProperty,
  ): Promise<void> {
    try {
      const data = { id, acceptedAt, acceptedBy, coAcceptedBy };
      await this.functions.httpsCallable(
        OnCallFunction.Core,
        'acceptUserAcknowledgment',
        managed,
      )(data);
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async getUser(managed?: ManagedProperty): Promise<User> {
    try {
      const resp = await this.functions.httpsCallable(OnCallFunction.Core, 'getUser', managed)({});
      return UserFactory.build(resp.data.user);
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async setEmail(
    email: string | null,
    options?: {
      swapWithManagerIfConflict?: boolean,
      managed?: ManagedProperty,
    },
  ): Promise<void> {
    try {
      const data = {
        email,
        swapWithManagerIfConflict: options?.swapWithManagerIfConflict,
      };
      await this.functions.httpsCallable(
        OnCallFunction.Core,
        'setEmail',
        options?.managed,
      )(data);
    } catch (err) {
      throw await this.handleErr(err);
    }
  }

  public async getProviders(
    inviteCode: string | undefined,
    providerId: string | undefined,
  ): Promise<Provider[]> {
    try {
      const data = { inviteCode, providerId };
      const resp = await this.functions.httpsCallable(
        OnCallFunction.Core,
        'getProviders',
      )(data);
      return resp.data.providers.map((p: ProviderJson) => {
        SessionUserSDK.conformReducedProvider(p);
        return ProviderFactory.build(p);
      });
    } catch (err) {
      throw await this.handleErr(err);
    }
  }
}
